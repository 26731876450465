/* App.css */
body, h1, p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: "Poppins", sans-serif;
}

.hero-headline {
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 0; /* Remove bottom margin to bring $COOKED closer */
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
}

.entrance-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  color: black;
  position: relative;
  overflow: hidden;
}

.entrance-animation h1 {
  opacity: 0;
  animation: fadeInOut 3s ease forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}