/* LandingPage.css */
.landing-page header {
  color: white;
  text-align: left;
  padding: 20px;
  background-size: cover;
  background-position: center;
  font-family: "Poppins", sans-serif;
}

.logo {
  width: 500px; /* Adjust based on your logo's aspect ratio */
}

.landing-page section {
  padding: 40px 20px;
  border-bottom: 1px solid #ccc;
}

.main-header {
  font-size: 3rem;
  margin-bottom: 20px;
  color: green;
}

.website-text {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: black;
}

.landing-page {
  font-family: "Poppins", sans-serif;
}

.hero-section {
  position: relative;
  height: 100vh;
  background: url("../assets/spaceKitchen.jpg") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-text-container {
  position: relative;
  z-index: 2;
  color: white;
  max-width: 800px;
  padding: 20px;
  /* background-color: rgba(0, 0, 0, 0.6); Semi-transparent black background */
  border-radius: 10px;
}

.hero-headline {
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 0; /* Remove bottom margin to bring $COOKED closer */
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.9);
}

.heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.3); */
}

.hero-subheadline {
  font-size: 2rem;
  margin-top: 0; /* Remove top margin to bring $COOKED closer */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.9);
}

.hero-by-text {
    font-size: 1rem;
    margin-bottom: 20px;
}

.hero-cta {
  font-size: 1.5rem;
  color: white;
  /* background-color: #474747; */
  width: 80%;
  justify-content: center;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-decoration: none;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.9);
}

.address {
  border: 1px solid #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  user-select: all;
  word-wrap: break-word;
  max-width: 100%;
  font-size: 1rem;
}

.hero-cta:hover {
  /* background-color: #505050; */
  cursor: pointer;
}

.hero-tagline {
  font-size: 1.2rem;
  margin-top: 20px;
  font-style: italic;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.3);
}

.hero-text-container p,
.hero-cta p {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Add text shadow for better visibility */
  background-color: rgba(
    36,
    35,
    35,
    0.5
  ); /* Semi-transparent white background for readability */
  padding: 5px; /* Add padding for better spacing */
  border-radius: 5px; /* Rounded corners for modern look */
}

/* Rest of your sections styled for scrolling */
.section {
  padding: 40px 20px;
  border-bottom: 1px solid #ccc;
}

.coin-container,
.buttons-container,
.contract-address {
  text-align: center; /* Center content */
}

.social-button {
  text-decoration: none;
  color: #f5f5f5;
  background-color: #444;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  border: 1px solid #444;
}

.social-button:hover {
  background-color: #666;
}

.content-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
}

.content-text {
  max-width: 600px; /* Adjust based on your preference */
  padding: 20px;
}

.content-text h1 {
  font-size: 3rem; /* Larger font size for the main header */
  margin-bottom: 20px;
}

.content-text p {
  font-size: 1.2rem; /* Larger font size for the paragraph */
}

.plate {
  height: 600px;
}

/* about section */
.about-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 80px 20px;
  background-color: #ffffff; /* Clean white background */
}

.about-content {
  display: flex;
  flex-direction: column;
    align-items: center;
}

.about-image {
  width: 40%; /* Adjust based on your preference */
  margin-bottom: 30px;
  transition: transform 0.3s ease-in-out;
}

.about-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.about-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.about-text {
  font-size: 1.2rem;
  color: #555;
  max-width: 800px;
  margin-bottom: 40px;
  text-align: center;
}

.sister-project {
  font-size: 1rem;
  color: #777;
  margin-top: 40px;
  text-align: center;
}

.sister-project a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.sister-project a:hover {
  color: #0056b3;
} /* end of about section */

.coin-image {
  width: 10%; /* Adjust based on your preference */
  display: block; /* This makes the image a block-level element */
  margin: 0 auto; /* This centers the image horizontally */
  transition: transform 0.3s ease-in-out;
}

/* roadmap section */
.roadmap-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 120px; /* Adjust padding as needed */
  background-color: #bfe2fa; /* Light blue background */
}

.roadmap-content {
  flex: 1;
  padding-right: 50px; /* Space between text and image */
}

.roadmap-title {
  font-size: 3rem;
  color: #333;
  margin-bottom: 30px;
}

.roadmap-text {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 30px;
}

.roadmap-steps {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 30px;
}

.roadmap-causes {
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
  display: block; /* Each cause on a new line */
  margin-bottom: 10px;
}

.roadmap-image {
  flex: 1;
  text-align: right; /* Align the image to the right */
}

.roadmap-image img {
  max-width: 100%;
  height: auto;
}

.charity-wallet {
    margin-bottom: 2rem;
  }
/* end roadmap section */

#about,
#roadmap,
#tokenomics {
  animation: slideIn 1s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {
  .landing-page section {
    padding: 40px 20px; /* Adjust padding for smaller screens */
  }

  .charity-wallet {
    font-size: 0.75rem;
    margin-bottom: 2rem;
    text-align: left;
  }

  .hero-section {
    position: relative;
    height: auto; /* Adjust height for mobile screens */
    background: url("../assets/spaceKitchen.jpg") no-repeat center center scroll; /* Change background attachment to scroll */
    background-size: cover; /* Ensure background covers the section */
    padding: 20px 10px; /* Add some padding if needed */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .about-section, .roadmap-section {
    padding: 40px 20px; /* Adjust padding for smaller screens */
    max-width: 100%; /* Ensure the section does not exceed the screen width */
    box-sizing: border-box; /* Include padding in the element's total width */
  }

  .about-content, .roadmap-content {
    max-width: 90%; /* Limit content width to 90% of its parent */
    margin: 0 auto; /* Center the content horizontally */
  }

  .about-section,
  .roadmap-section {
    flex-direction: column; /* Stack images above text on mobile */
    text-align: center; /* Center-align text for better readability */
    padding: 40px 40px; /* Adjust padding for smaller screens */
  }

  .about-image,
  .roadmap-image img {
    width: 80%; /* Adjust image width on smaller screens */
    margin: 0 auto 30px; /* Center images and add margin above */
    transition: none; /* Disable hover effect on mobile */
  }

  .coin-image {
    width: 20%; /* Adjust based on your preference */
    margin: 0 auto 30px; /* Center images and add margin above */

    transition: none;
  }

  .about-content,
  .roadmap-content {
    padding-right: 0; /* Remove right padding on mobile */
  }

  .roadmap-image {
    text-align: center; /* Center-align the image */
  }

  .roadmap-item {
    text-align: left;
  }

  .buttons-container,
  .contract-address {
    justify-content: center; /* Center buttons and address if using flex */
  }
  .social-button {
    margin: 0 5px; /* Adjust margins for smaller screens */
  }

  .address {
    max-width: 100%; /* Ensure address does not exceed screen width */
    font-size: .75rem; /* Adjust font size for better readability */
  }
}
