.fire-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  pointer-events: none;
  mix-blend-mode: screen;
}

.smoke-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: none;
  z-index: 1;
  pointer-events: none;
  mix-blend-mode: screen;
  opacity: 0.5;
}

@media (max-width: 768px) {
  /* .fire-overlay {
    display: none;
  } */
}
